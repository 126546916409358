import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"

import YC_2018 from "../../../static/images/ministries/youth-corps/2018.png"
import IntensivesWork from "../../../static/images/ministries/youth-corps/intensives_work.png"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/ministries.css"


function YC () {
	
	const subheaderData = {
		main:"Summer Youth Corps",
		sub:""
	}

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content eri-colors youth-corps">
				<div>
					<Subheader data={subheaderData} />
				</div>
				<div className="youth-corps-application">
<iframe  className="application-iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSfB6CwHVdaSsmZH_KAtB4rhj7a32Zzaxa4G0KjbjYdr-LnJYA/viewform?embedded=true" width="1000" height="2506" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>				</div>
			</main>
			<Footer />		
		</div>
		)
}

export default YC 